import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "FHEM with Docker",
  "path": "/Frequently_Asked_Question/FHEM_with_Docker/",
  "dateChanged": "2022-05-12",
  "author": "Mike Polinowski",
  "excerpt": "Use the official Docker image to set up your FHEM Server.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - FHEM with Docker' dateChanged='2018-03-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the official Docker image to set up your FHEM Server.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/FHEM_mit_Docker/' locationFR='/fr/Frequently_Asked_Question/FHEM_with_Docker/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "fhem-with-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fhem-with-docker",
        "aria-label": "fhem with docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FHEM with Docker`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I found your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/FHEM_on_a_Raspberry_Pi/"
      }}>{`FHEM Installation Guide`}</a>{` for a Raspberry Pi. But I am wondering if is it possible to install the FHEM Server using Docker as well?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Yes, there is an official `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/fhem/fhem"
      }}>{`FHEM Docker Image`}</a>{` available in the Docker Hub.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You will also find detailed instructions `}<a parentName="p" {...{
          "href": "https://forum.fhem.de/index.php?topic=89745.0"
        }}>{`in the official FHEM Forum`}</a>{`.`}</p>
    </blockquote>
    <p>{`You can download the image using the `}<a parentName="p" {...{
        "href": "https://docs.docker.com/get-docker/"
      }}>{`Docker CLI`}</a>{` - I am opting for the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/fhem/fhem"
      }}>{`Debian Bullseye Build`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull fhem/fhem:bullseye`}</code></pre></div>
    <p>{`Create a directory that you can use to persist the FHEM generated data - make sure that the Docker user has the rights to write into this directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/fhem
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span>{`:1000 /opt/fhem`}</code></pre></div>
    <p>{`FHEM uses the port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8083`}</code>{` for it's web interface and port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`7072`}</code>{` for a healthcheck. You can either open those ports or simply use your host system network stack:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d --rm --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host -v /opt/fhem:/opt/fhem --name fhem fhem/fhem:bullseye`}</code></pre></div>
    <p>{`Make sure that your firewall allows access to the web frontend:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ufw allow `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8083`}</span>{`/tcp                                                                                                      
Rule added
Rule added `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`v6`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

ufw reload`}</code></pre></div>
    <p>{`And access the web frontend on your server IP + port 8083, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.111:8083/fhem`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c450e2add2b04772ee17a59f27aadb7/f680b/FHEM_in_Docker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA20lEQVQY033NbUvDMBAH8H7/r6UvJk6Ze+gspSpr2qTmqVlbmzQPJ8lEEWXw4/gfx91l1VAttrdWLEtkrUiZzzPVmmlNjWGX0R8su5kEosemXrftBqGHul4j9MhF3vfP56FUqtD6DQADtL9rlN2P9DzXjO57VUpZMHrg/ChFsZhT8K2zKHLI+cgmPlxO4OxWIsJLyXOMt5QeOrIlZMdl3ondu9xj9iRVMY0vk64m8zqaajCVcaev5bsPsQANHjnXxFe+8b7xoQ3wjcA/0vJKNSZ0ADikPiHwk6/5BIQ9Ui0Rv719AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c450e2add2b04772ee17a59f27aadb7/e4706/FHEM_in_Docker_01.avif 230w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/d1af7/FHEM_in_Docker_01.avif 460w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/7f308/FHEM_in_Docker_01.avif 920w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/6a1a1/FHEM_in_Docker_01.avif 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c450e2add2b04772ee17a59f27aadb7/a0b58/FHEM_in_Docker_01.webp 230w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/bc10c/FHEM_in_Docker_01.webp 460w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/966d8/FHEM_in_Docker_01.webp 920w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/17ad2/FHEM_in_Docker_01.webp 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c450e2add2b04772ee17a59f27aadb7/81c8e/FHEM_in_Docker_01.png 230w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/08a84/FHEM_in_Docker_01.png 460w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/c0255/FHEM_in_Docker_01.png 920w", "/en/static/4c450e2add2b04772ee17a59f27aadb7/f680b/FHEM_in_Docker_01.png 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c450e2add2b04772ee17a59f27aadb7/c0255/FHEM_in_Docker_01.png",
              "alt": "FHEM with Docker",
              "title": "FHEM with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      